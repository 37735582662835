import React, { Fragment, useState } from "react";
import Papa from "papaparse";
import DataTable from "../../Components/Table/DataTable";
import MetaData from "../../Components/Helmet/Helmet";
import SideBar from "../../Components/SideBar/SideBar";
import Header from "../../Components/Header/Header";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";

// added comment
const CSVTable = () => {
  const [data, setData] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setData(results.data);
        },
      });
    }
  };

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <Fragment>
      <div
        className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
      >
        <MetaData title={`Upload File`} />
        <div>
          <SideBar handleIsToggle={handleIsToggle} />
        </div>
        <div>
          <Header />
          <div className="wrapper-picker-container">
            <label className="browser-file-csv">
              Choose File
              <input type="file" accept=".csv" onChange={handleFileChange} />
            </label>
            <div className="mt__4">
              {data?.length > 0 ? (
                <div className="table-wrapper-pagination">
                  <div className="table-container-pagination">
                    <DataTable csvData={data} header={Object.keys(data[0])} />
                  </div>
                </div>
              ) : (
                <NoDataFromApi
                  height={true}
                  title={"Please Choose CSV Files to Populate data..."}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CSVTable;
