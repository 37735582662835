import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Store";
import { positions, transitions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

// Override console methods to suppress logs
console.log = () => {};
console.warn = () => {};
console.error = () => {};

const root = ReactDOM.createRoot(document.getElementById("root"));

const options = {
  timeout: 5000,
  position: positions.BOTTOM_RIGHT,
  transition: transitions.SCALE,
};

root.render(
  <Provider store={store}>
    <AlertProvider
      containerStyle={{ zIndex: 999999 }}
      template={AlertTemplate}
      {...options}
    >
      <App />
    </AlertProvider>
  </Provider>
);
