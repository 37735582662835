import React from "react";
import Headline from "../../Components/HeadlineSpecial/Headline";
import LoaderAlone from "../../Components/Loader/LoaderAlone";
import Header from "../../Components/Header/Header";
import SideBar from "../../Components/SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  createPicklistRequirementActions,
  getAllFiltersPickingModuleActions,
  getOrderListTableActions,
} from "../../Actions/PickerMgmt/PickerMgmtActions";
import Dropdown from "../../Components/Dropdown/Dropdown";
import DataTable from "../../Components/Table/DataTable";
import {
  mark_lost_not_found,
  orderTable,
  picklist_order_type_list,
} from "../../DummyArray/TableArray/TableData";
import { useState } from "react";
import { useCallback } from "react";
import { Pagination } from "../../Components/Pagination/Pagination";
import axios from "axios";
import { BASE_URL } from "../../Base_URL/BaseURL";
import NoDataFromApi from "../../Components/Loader/NoDataFromApi";
import "./CreateOrder.css";
import { Button } from "@material-ui/core";
import { useAlert } from "react-alert";
import { CREATE_PICKLIST_REQUIREMENT_RESET } from "../../Constants/PickerMgmt/PickerManagementConstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useMemo } from "react";
import ModalContainer from "../../Components/Modal/Modal";
import MetaData from "../../Components/Helmet/Helmet";
import Loader from "../../Components/Loader/Loader";

const CreateOrder = () => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { list_orders, status, loading } = useSelector(
    (state) => state.orderList
  );

  const [limit, setLimit] = useState(10);
  const [changeLimit, setChangeLimit] = useState(false);

  const handleLimit = (event) => {
    setChangeLimit(true);
    if (event.key === "Enter") {
      setPage(1);
      // dispatch(getOrderListTableActions("list_orders", 1, token, limit));
      fetchFilters();
    }
  };

  const handleLimitTag = (e) => {
    setChangeLimit(true);
    setLimit(e.target.value);
  };

  const {
    message,
    status: CreateStatus,
    isCreated,
    loading: create_loading,
  } = useSelector((state) => state.createPicklist);

  // pagination code starts here
  const [orderList, setOrder_List] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [filtersTrue, setFilterTrue] = useState(false);
  const [pagination, setPagination] = useState(0);

  const fetch = useCallback(
    (page) => {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}`, {
          service_name: "list_orders",
          page,
          token,
          limit,
        })
        .then((response) => {
          setOrder_List(response?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    },
    [limit, token]
  );

  const handlePage = useCallback(
    (page, paginate) => {
      setPagination(page);
      setPage(page);
      if (!filtersTrue && pagination >= 0 && paginate) {
        fetch(page);
      }
    },
    [fetch, filtersTrue, pagination]
  );

  const fetchPicklist = useCallback(() => {
    list_orders && setOrder_List(list_orders && list_orders?.data);
  }, [list_orders]);

  useEffect(() => {
    fetchPicklist();
  }, [fetchPicklist]);

  // filters code starts here
  const [purchased_from, setPurchased_from] = useState(null);

  const [purchased_to, setPurchased_to] = useState(null);

  const [tracking_no, setTracking_no] = useState(null);

  const [portal_name, setPortal_name] = useState("");
  const [portal_id, setPortal_id] = useState("");

  const [order_no, setOrder_no] = useState("");
  const [is_picklist, setis_picklist] = useState("");
  const [is_picklist_id, setis_picklist_id] = useState("");

  const [picklist_order_type_name, setPicklist_order_type_name] = useState("");
  const [picklist_order_type, setPicklist_order_type] = useState("");

  const [filterTotal_pages, setFilter_total_pages] = useState(0);
  const [filter_Loading, setFilter_loading] = useState(false);

  const [picklist_id, setPicklist_id] = useState("");

  const [created_Date, setCreated_Date] = useState(null);
  const [to_created_Date, setTo_created_Date] = useState(null);

  const handleisPicklist = (val) => {
    setis_picklist(val?.id);
    setFilterTrue(true);
    setis_picklist_id(val?.value);
    handlePage(1, false);
  };

  // New Filter
  const [is_label_generated, setis_label_generated] = useState("");
  const [is_label_generated_value, setIsLabel_generated_value] = useState("");

  const [is_bulk_label_generated, setis_bulk_label_generated] = useState("");
  const [is_bulk_label_generated_value, setis_bulk_label_generated_value] =
    useState("");

  const handleLabelGenerated = (val) => {
    setis_label_generated(val?.id);
    setIsLabel_generated_value(val?.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handleBulkLabelGenerated = (val) => {
    setis_bulk_label_generated(val?.id);
    setis_bulk_label_generated_value(val?.value);
    setFilterTrue(true);
    handlePage(1, false);
  };
  // New Filter

  const handleOrderNo = (e) => {
    setOrder_no(e.target.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handlePicklist_id = (e) => {
    setPicklist_id(e.target.value);
    setFilterTrue(true);
    handlePage(1, false);
  };

  const handleKeyOrderNo = (event) => {
    if (event.key === "Enter") {
      setFilterTrue(true);
      fetchFilters();
      handlePage(1, false);
    }
  };

  const handlePicklistId = (event) => {
    if (event.key === "Enter") {
      setFilterTrue(true);
      fetchFilters();
    }
  };

  const handleTrackingNo = (e) => {
    setFilterTrue(true);
    setTracking_no(e.target.value);
    handlePage(1, false);
  };

  const handleKeyTrackingNo = (event) => {
    if (event.key === "Enter") {
      setFilterTrue(true);
      fetchFilters();
      handlePage(1, false);
    }
  };

  const handlePortalName = (val) => {
    setFilterTrue(true);
    setPortal_name(val?.value);
    setPortal_id(val?.id);
    handlePage(1, false);
  };

  const handlePicklistOrderType = (val) => {
    setFilterTrue(true);
    setPicklist_order_type(val?.id);
    setPicklist_order_type_name(val?.value);
    handlePage(1, false);
  };

  const handlePurchasedOn = (date) => {
    setFilterTrue(true);
    setPurchased_from(date);
    handlePage(1, false);
  };

  const handleCreatedOn = (date) => {
    setFilterTrue(true);
    setPurchased_to(date);
    handlePage(1, false);
  };

  const handleCreatedOn_Date = (date) => {
    setFilterTrue(true);
    setCreated_Date(date);
    handlePage(1, false);
  };

  const handleCreatedOn_toDate = (date) => {
    setFilterTrue(true);
    setTo_created_Date(date);
    handlePage(1, false);
  };

  let from_purchase_date =
    purchased_from !== "" ? moment(purchased_from).format("yyyy-MM-DD") : null;
  let to_purchase_date =
    purchased_to !== "" ? moment(purchased_to).format("yyyy-MM-DD") : null;

  let from_created_on =
    created_Date !== "" ? moment(created_Date).format("yyyy-MM-DD") : null;
  let to_created_on =
    to_created_Date !== ""
      ? moment(to_created_Date).format("yyyy-MM-DD")
      : null;

  let filters = useMemo(() => {
    const dataToSend = {
      portal_id,
      picklist_order_type,
      from_purchase_date,
      order_no,
      tracking_no,
      to_purchase_date,
      is_picklist,
      picklist_id,
      from_created_on,
      to_created_on,
      is_label_generated,
      is_bulk_label_generated,
    };
    // Filtering empty value
    const filter_data = Object.fromEntries(
      Object.entries(dataToSend).filter(
        ([_, value]) =>
          value !== "" && value !== null && value !== "Invalid date"
      )
    );
    return filter_data;
  }, [
    portal_id,
    picklist_order_type,
    order_no,
    tracking_no,
    to_purchase_date,
    from_purchase_date,
    is_picklist,
    picklist_id,
    from_created_on,
    to_created_on,
    is_bulk_label_generated,
    is_label_generated,
  ]);

  const fetchFilters = useCallback(() => {
    setFilter_loading(true);
    axios
      .post(`${BASE_URL}`, {
        service_name: "list_orders",
        token,
        page,
        filters,
        limit,
      })
      .then((response) => {
        if (response && response?.data?.status === 0) {
          alert.error(response && response?.data?.msg);
        } else {
          setFilter_total_pages(response?.data?.total_pages);
          setOrder_List(response?.data?.data);
          setFilter_loading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setFilter_loading(false);
      });
  }, [token, page, filters, alert, limit]);

  useEffect(() => {
    if (filtersTrue === true) {
      fetchFilters();
    } else if (
      filtersTrue === false &&
      pagination === 0 &&
      changeLimit === false
    ) {
      dispatch(getOrderListTableActions("list_orders", 1, token, limit));
    }
  }, [
    dispatch,
    token,
    filtersTrue,
    fetchFilters,
    pagination,
    limit,
    changeLimit,
  ]);

  // filter code ends here

  const [isIndex, setIsindex] = useState([]);
  const [isOk, setIsOk] = useState(true);
  const [oldIndex, setOldIndex] = useState([]);
  let order_ids = [...new Set([...isIndex, ...oldIndex])];

  const handleIndex = (index) => {
    setOldIndex((prev) => [...prev, index]);
    if (isIndex.includes(index) || oldIndex.includes(index)) {
      setIsindex((prevNum) => prevNum.filter((num) => num !== index));
      setOldIndex((prevNum) => prevNum.filter((num) => num !== index));
    } else {
      setIsindex((prev) => [...prev, index]);
      setOldIndex((prev) => [...prev, index]);
    }
  };

  const handleIndexAll = () => {
    setIsOk(!isOk);
    if (isOk) {
      orderList &&
        orderList.forEach((i) => {
          if (i.is_pick_list === 0 && i.show_checkbox === 1) {
            if (isIndex.includes(i.id)) {
              setIsindex((prevNum) => prevNum.filter((num) => num !== i.id));
            } else {
              setIsindex((prev) => [...prev, i.id]);
            }
          }
        });
    } else {
      setIsindex([]);
      setOldIndex([]);
    }
  };

  const handleNull = () => {
    setIsindex([]);
    setOldIndex([]);
    setIsOk(!isOk);
  };

  let All_Filters = useMemo(() => {
    const dataToSend = ["portals"];
    return dataToSend;
  }, []);

  const { all_filters, loading: allFilter_loading } = useSelector(
    (state) => state.allPickingModuleFilters
  );

  let portal_name_list = all_filters && all_filters?.portals;

  useEffect(() => {
    dispatch(getAllFiltersPickingModuleActions("filters", token, All_Filters));
  }, [dispatch, token, All_Filters]);

  const handlePicklistCreate = () => {
    if (order_ids && order_ids.length > 0) {
      dispatch(
        createPicklistRequirementActions(
          "create_picklist_requirement",
          token,
          order_ids
        )
      );
    } else {
      alert.error("Please Select Ids...");
    }
  };

  useEffect(() => {
    if (CreateStatus === 1 && isCreated) {
      alert.success("Picklist Created Successfully!");
      dispatch(getOrderListTableActions("list_orders", 1, token, limit));
      setPage(1);
      dispatch({
        type: CREATE_PICKLIST_REQUIREMENT_RESET,
      });
    } else if (CreateStatus === 0 && isCreated) {
      alert.error(message);
      dispatch({
        type: CREATE_PICKLIST_REQUIREMENT_RESET,
      });
    }
  }, [dispatch, token, CreateStatus, isCreated, alert, message, limit]);

  let ActiveOrders = [];
  orderList &&
    orderList.forEach((i) => {
      if (i.is_pick_list === 0 && !ActiveOrders.includes(i.id)) {
        ActiveOrders.push(i);
      } else {
        return null;
      }
    });

  const [isIndexs, setIsIndexs] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isData, setIsData] = useState([]);

  const closeModal = () => {
    setIsIndexs(null);
    setIsOpen(false);
  };

  const renderModal = () => {
    if (isIndex !== null) {
      return (
        <ModalContainer
          Isopen={isOpen}
          index={isIndexs}
          closeModal={closeModal}
          isData={isData}
        />
      );
    }
    return null;
  };

  const handlePicklistDetails = (data, index) => {
    setIsIndexs(index);
    setIsData(data);
    setIsOpen(true);
  };

  const resteFilter = () => {
    setPortal_id("");
    setPortal_name("");
    setPicklist_order_type("");
    setPicklist_order_type_name("");
    setPurchased_from("");
    setOrder_no("");
    setTracking_no("");
    setPurchased_to("");
    setCreated_Date("");
    setTo_created_Date("");
    setis_picklist("");
    setPicklist_id("");
    setis_picklist_id("");
    setis_bulk_label_generated("");
    setis_bulk_label_generated_value("");
    setis_label_generated("");
    setIsLabel_generated_value("");
    setPage(1);
  };

  // useEffect(() => {
  //   if (
  //     portal_id ||
  //     portal_name ||
  //     picklist_order_type ||
  //     picklist_order_type_name ||
  //     purchased_from ||
  //     purchased_to ||
  //     order_no ||
  //     tracking_no ||
  //     created_Date ||
  //     to_created_Date ||
  //     is_picklist ||
  //     is_picklist_id
  //   ) {
  //     handlePage(1);
  //   }
  // }, [
  //   portal_id,
  //   portal_name,
  //   picklist_order_type,
  //   picklist_order_type_name,
  //   purchased_from,
  //   purchased_to,
  //   order_no,
  //   tracking_no,
  //   created_Date,
  //   to_created_Date,
  //   is_picklist,
  //   is_picklist_id,
  //   handlePage
  // ]);

  // xxxxxxxxxxxxxxxxxxxx

  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = (val) => {
    setIsToggle(!val);
  };

  return (
    <div
      className={`grid-sidebar-row ${isToggle && "active-grid-sidebar-row"}`}
    >
      <MetaData title={`Create Picklist`} />
      <div>
        <SideBar handleIsToggle={handleIsToggle} />
      </div>
      <div>
        <Header />
        <div className="wrapper-picker-container">
          {loading ? (
            <LoaderAlone />
          ) : (
            <>
              {status === 0 ? (
                <NoDataFromApi
                  height={true}
                  title="Something Went Wrong, Please Try Logout & ReLogin Again..."
                />
              ) : (
                <>
                  <Headline title="Create Picklist" hide={true} />

                  {/* dropdown */}
                  {/* dropdown */}
                  <div className="dropdown-grid-row">
                    <div>
                      <Dropdown
                        placeholder="Portal Name"
                        dropdown={portal_name_list && portal_name_list}
                        value={portal_name}
                        onData={handlePortalName}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="PickList Order Type"
                        dropdown={
                          picklist_order_type_list && picklist_order_type_list
                        }
                        value={picklist_order_type_name}
                        onData={handlePicklistOrderType}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="From Purchased Date"
                        selected={purchased_from}
                        onChange={(date) => handlePurchasedOn(date)}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="To Purchased Date"
                        selected={purchased_to}
                        onChange={(date) => handleCreatedOn(date)}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Picklist order"
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={is_picklist_id}
                        onData={handleisPicklist}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="text"
                        value={order_no}
                        onChange={(e) => handleOrderNo(e)}
                        placeholder="Order Code"
                        onKeyPress={handleKeyOrderNo}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="text"
                        onChange={(e) => handleTrackingNo(e)}
                        value={tracking_no}
                        placeholder="Tracking No"
                        onKeyPress={handleKeyTrackingNo}
                      />
                    </div>
                    <div className="date_handover">
                      <input
                        type="number"
                        min={0}
                        value={picklist_id}
                        onChange={(e) => handlePicklist_id(e)}
                        placeholder="Picklist Id"
                        onKeyPress={handlePicklistId}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="From Created On"
                        selected={created_Date}
                        onChange={(date) => handleCreatedOn_Date(date)}
                      />
                    </div>
                    <div>
                      <DatePicker
                        className="date-picker"
                        placeholderText="To Created On"
                        selected={to_created_Date}
                        onChange={(date) => handleCreatedOn_toDate(date)}
                      />
                    </div>
                    {/* New Filter */}
                    <div>
                      <Dropdown
                        placeholder="Label Generated"
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={is_label_generated_value}
                        onData={handleLabelGenerated}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    <div>
                      <Dropdown
                        placeholder="Bulk Label Generated"
                        dropdown={mark_lost_not_found && mark_lost_not_found}
                        value={is_bulk_label_generated_value}
                        onData={handleBulkLabelGenerated}
                        noShow={true}
                        loading={allFilter_loading}
                      />
                    </div>
                    {/* New Filter */}
                    <div>
                      <div className="clear-filter">
                        <Button onClick={() => resteFilter()}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* dropdown */}
                  {/* dropdown */}

                  {/* table */}
                  <div className="table-wrapper-pagination">
                    <div className="table-container-pagination">
                      <div className="header-limit-checkbox">
                        <div>
                          <div>
                            <input
                              type="checkbox"
                              checked={
                                ActiveOrders && ActiveOrders?.length > 0
                                  ? ActiveOrders.every((i) =>
                                      i.is_pick_list === 0 &&
                                      order_ids.includes(i.id)
                                        ? true
                                        : false
                                    )
                                  : false
                              }
                              readOnly
                              id="checkbox"
                            />
                            <label
                              onClick={() => handleIndexAll()}
                              htmlFor="checkbox"
                            ></label>
                          </div>
                          <p className="lable-select-all">Select all</p>
                          <Button
                            disabled={create_loading ? true : false}
                            type="submit"
                            onClick={handlePicklistCreate}
                            className="create-picklist-requirements"
                          >
                            {create_loading ? (
                              <Loader isSmall={true} />
                            ) : (
                              "Create"
                            )}
                          </Button>
                        </div>
                        <div className="result-flex-grid">
                          <p className="result-out">
                            *Result {page} in{" "}
                            {filtersTrue
                              ? filterTotal_pages
                              : list_orders && list_orders?.total_pages}
                          </p>
                          <div className="limit">
                            <input
                              type="text"
                              name="limit"
                              id="limit"
                              value={limit}
                              onKeyPress={handleLimit}
                              onChange={(e) => handleLimitTag(e)}
                            />
                          </div>
                        </div>
                      </div>
                      {filter_Loading ? (
                        <LoaderAlone />
                      ) : (
                        <DataTable
                          orderTable={orderList && orderList}
                          header={orderTable}
                          onSelect={handleIndex}
                          isIndexSelectAll={order_ids}
                          isOk={isOk}
                          handleOrder={handlePicklistDetails}
                          avoidUnsedApiCall={false}
                        />
                      )}
                    </div>
                  </div>

                  <div className="rendering-pagination">
                    <Pagination
                      uniqueKey={`ScreenA-${page}`}
                      page={page}
                      isLoading={isLoading ? isLoading : filter_Loading}
                      totalPage={
                        filtersTrue
                          ? filterTotal_pages
                          : list_orders && list_orders?.total_pages
                      }
                      onPageChange={handlePage}
                      onHandleCheckbox={handleNull}
                    />
                    {/* <div>
                      <div className="limit">
                        <input
                          type="text"
                          name="limit"
                          id="limit"
                          value={limit}
                          onKeyPress={handleLimit}
                          onChange={(e) => handleLimitTag(e)}
                        />
                      </div>
                    </div> */}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default CreateOrder;
