export const routes = [
  {
    path: "/",
    title: "Dashboard",
    icon: require("../../Assets/Icons/SideBar-Icons/frame.png"),
  },
  {
    path: "/picker-management",
    title: "Picker Management",
    icon: require("../../Assets/Icons/SideBar-Icons/people.png"),
  },
  {
    path: "/create-order",
    title: "Create Picklist",
    icon: require("../../Assets/Icons/SideBar-Icons/edit.png"),
  },
  {
    path: "/picklist",
    title: "Picklist",
    icon: require("../../Assets/Icons/SideBar-Icons/task-square.png"),
  },
  {
    path: "/secondary-picklist",
    title: "Secondary Orders",
    icon: require("../../Assets/Icons/SideBar-Icons/menu-board.png"),
  },
  {
    path: "/communication-alert",
    title: "Communication/Alert",
    icon: require("../../Assets/Icons/SideBar-Icons/messages-2.png"),
  },
  {
    path: "/portals-management",
    title: "Portals Management",
    icon: require("../../Assets/Icons/SideBar-Icons/bubble.png"),
  },
  {
    path: "/courier-management",
    title: "Courier Management",
    icon: require("../../Assets/Icons/SideBar-Icons/truck-fast.png"),
  },
  {
    path: "/activity",
    title: "Activity",
    icon: require("../../Assets/Icons/SideBar-Icons/clock.png"),
  },
  {
    path: "/lost-sublocation",
    title: "Lost Sublocation",
    icon: require("../../Assets/Icons/SideBar-Icons/gps.png"),
    subRoutes: [
      {
        path: "/mark-lost-request",
        title: "Mark Lost Request",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
      {
        path: "/advance-sublocation-transfer",
        title: "Adv. SubLocation Trans.",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
      {
        path: "/advance-sublocation-transfer-summary",
        title: "Adv. SubLocation Trans. Summary",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
      {
        path: "/session-summary",
        title: "Session Summary",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
    ],
  },
  {
    path: "/single-label-generator",
    title: "Label Generator",
    icon: require("../../Assets/Icons/SideBar-Icons/stickynote.png"),
  },
  {
    path: "/tote-management",
    title: "Tote Management",
    icon: require("../../Assets/Icons/SideBar-Icons/bag.png"),
  },
  {
    path: "/delivery-partner",
    title: "Order Handover",
    icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
    subRoutes: [
      {
        path: "/order-summary",
        title: "Order Summary",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
    ],
  },
  {
    path: "/create-reverse-handover",
    title: "Order Reverse",
    icon: require("../../Assets/Icons/SideBar-Icons/bag-happy.png"),
    subRoutes: [
      {
        path: "/reverse-handover-summary",
        title: "Reverse Summary",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
    ],
  },
  {
    path: "/packing-material",
    title: "Packing Material",
    icon: require("../../Assets/Icons/SideBar-Icons/archive.png"),
    subRoutes: [
      {
        path: "/add-ideal-consumptions",
        title: "Add Ideal Consumptions",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
      {
        path: "/consumption-report",
        title: "Consumption Report",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
    ],
  },
  {
    path: "/box-qty-audit",
    title: "Box Audit",
    icon: require("../../Assets/Icons/SideBar-Icons/box.png"),
    subRoutes: [
      {
        path: "/box-qty-audit",
        title: "Box Qty Audit",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
      {
        path: "/box-unit-qty-audit",
        title: "Box Unit Qty Audit",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
      {
        path: "/box-audit-transaction-summary",
        title: "Box Audit Tran. Summary",
        icon: require("../../Assets/Icons/SideBar-Icons/bag-tick.png"),
      },
    ],
  },
  {
    path: "/predefined-problems",
    title: "Predefined Problems",
    icon: require("../../Assets/Icons/SideBar-Icons/problem-solving.png"),
  },
  {
    path: "/bypass",
    title: "Add/Remove ByPass",
    icon: require("../../Assets/Icons/SideBar-Icons/skip.png"),
  },
  {
    path: "/settings",
    title: "Settings",
    icon: require("../../Assets/Icons/SideBar-Icons/settings.png"),
  },
  {
    path: "/upload-csv",
    title: "Upload CSV",
    icon: require("../../Assets/Icons/SideBar-Icons/csv.png"),
  },
];
